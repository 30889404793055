import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  withRouter,
} from 'react-router-dom'
import {
  Field,
  formValueSelector,
  reduxForm,
  change,
  initialize,
  reset,
} from 'redux-form'
import {
  TextField,
  Select,
} from 'redux-form-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  getProfile,
  getAreas,
  createArea,
  editArea,
} from '../redux/api/actions'
import validate from '../utils/validators/areaValidator'
import PotluckMap from '../components/PotluckMap/PotluckMap'

const FORM_NAME = 'area'

const CITIES = [
  'Tokyo',
  'Yokohama',
  'Osaka',
  'Nagoya',
  'Sapporo',
  'Kobe',
  'Kyoto',
  'Fukuoka',
  'Chigasaki',
  'Kagoshima',
]

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  container: {
    margin: theme.spacing.unit * 3,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: '1',
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldHorizontalContainer: {
    display: 'flex',
    marginLeft: theme.spacing.unit * -1.5,
    marginRight: theme.spacing.unit * -1.5,
  },
  formFieldHorizontal: {
    flex: 1,
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
  },
  formFieldSelect: {
    minWidth: 180,
  },
  map: {
    position: 'relative',
    height: 350,
    marginBottom: 0,
  },
})

const DEFAULT_MAP_CENTER = {
  lat: 35.656869,
  lng: 139.696711,
}

class Area extends Component {
  state = {
    loading: false,
  }

  hasLoaded = () => this.props.areas.loaded

  onClickMap = (props, map, event) => {
    const clickCenter = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    }

    this.props.change('latitude', clickCenter.lat)
    this.props.change('longitude', clickCenter.lng)

    map.panTo(new props.google.maps.LatLng(clickCenter.lat, clickCenter.lng))
  }

  onPressSave = async values => {
    this.setState({ loading: true })

    if (this.props.areaId === 'new') {
      const newArea = await this.props.createArea(values)
      await this.props.getAreas()

      window.location = `/areas/${newArea.id}`
    } else {
      await this.props.editArea(this.props.areaId, values)
      await this.props.getAreas()
      this.setState({ loading: false })
    }
  }

  componentWillMount() {
    this.props.getProfile()
    this.props.getAreas()
  }

  render() {
    let mapCenter = DEFAULT_MAP_CENTER
    let mapLocations = []

    if (this.props.latitude && this.props.longitude) {
      mapCenter = {
        lat: parseFloat(this.props.latitude, 10),
        lng: parseFloat(this.props.longitude, 10),
      }

      mapLocations = [{
        position: mapCenter,
      }]
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.container}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.name}
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={this.props.pristine || this.props.invalid || this.state.loading}
              className={this.props.classes.button}
              onClick={this.props.handleSubmit(this.onPressSave)}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
              Save
            </Button>
          </Toolbar>
          <div className={this.props.classes.content}>
            <form>
              <Field
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
              <FormControl variant="outlined">
                <InputLabel htmlFor="city">
                  City
                </InputLabel>
                <Field
                  name="city"
                  label="City"
                  variant="outlined"
                  fullWidth
                  component={Select}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                  {CITIES.map((city, index) => (
                    <MenuItem
                      key={index}
                      value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <div className={this.props.classes.formFieldHorizontalContainer}>
                <Field
                  name="latitude"
                  label="Latitude"
                  variant="outlined"
                  type="number"
                  component={TextField}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)} />
                <Field
                  name="longitude"
                  label="Longitude"
                  variant="outlined"
                  type="number"
                  component={TextField}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)} />
              </div>
              <div className={classNames(this.props.classes.formField, this.props.classes.map)}>
              {this.hasLoaded() && (
                <PotluckMap
                  initialCenter={mapCenter}
                  markers={mapLocations}
                  onClickMap={this.onClickMap} />
              )}
              </div>
            </form>
          </div>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let initialValues
  const areaId = ownProps.match && ownProps.match.params.areaId

  if (areaId && state.api.areas.default.data) {
    const area = state.api.areas.default.data.find(({ id }) => id === parseInt(areaId, 10))

    if (area) {
      initialValues = {
        name: area.name,
        city: area.city,
        latitude: area.latitude,
        longitude: area.longitude,
      }
    } else if (areaId === 'new') {
      initialValues = {
        city: CITIES[0],
      }
    }
  }

  return {
    areas: state.api.areas.default,
    areaId,
    initialValues,
    name: selector(state, 'name'),
    city: selector(state, 'city'),
    latitude: selector(state, 'latitude'),
    longitude: selector(state, 'longitude'),
  }
}

const mapDispatchToProps = {
  getProfile,
  getAreas,
  createArea,
  editArea,
  change,
  initialize,
  resetForm: reset,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Area))))


